import "./App.css";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import Hero from "./components/hero/hero";
import Products from "./components/products/products";
import Slider from "./components/slider/slider";
import Testimonials from "./components/testimonials/testimonials";
import Virtual from "./components/virtual/virtual";

function App() {
  return (
    <div className="App">
      <Header/>
      <Hero/>
      <Slider/>
      <Virtual/>
      <Products/>
      <Testimonials/>
      <Footer/>
    </div>
  );
}

export default App;
