import css from './products.module.css'
import Plane from '../../assets/plane.png'
import { ProductsData } from '../../data/products';
import { useState } from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react'



const Products = () => {

    const [parent] = useAutoAnimate();

    const [MenueProducts, setMenuProducts] = useState (ProductsData)

    const filter = (type) =>{
        setMenuProducts(ProductsData.filter((p)=>p.type === type))

    }


    return ( 

        <div className={css.container}>
            
            <img src={Plane} alt="" />
            <h1>Our Featured Products</h1>

            {/* {Products} */}

            <div className={css.products}>
                <ul className={css.menu}>
                    <li onClick={()=>setMenuProducts(ProductsData)}>All</li>
                    <li onClick={()=>filter('skin care')}>Skin Care</li>
                    <li onClick={()=>filter('conditioner')}>Conditioners</li>
                    <li onClick={()=>filter('foundation')}>Foundations</li>
                </ul>

                <div className={css.list} ref={parent}>

                    {
                        MenueProducts.map((product,i)=>(
                            <div key={i} className={css.product}>
                                <div className='left-s'>
                                    <div className='name'>
                                        <span>{product.name}</span>
                                        <span>{product.detail}</span>
                                    </div>
                                    <span> {product.price}$ </span>
                                    <div>Show Now</div>
                                </div>

                                <img src={product.img} className='imgproduct' alt="" />
                            </div>
                        ))
                    }

                </div>

            </div>

        </div>

     );
}
 
export default Products;