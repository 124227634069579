import css from './testimonials.module.css'
import Hero from '../../assets/testimonialHero.png'
import {Swiper} from 'swiper/react';
import {TestimonialsData} from '../../data/testimonials'
import { SwiperSlide } from 'swiper/react';

const Testimonials = () => {
    return ( 

        <div className={css.testimonials}>

            <div className={css.wrapper}>
                
                <div className={css.container}>
                <span>Top Rated</span>
                <span>SEEDILY SAY HAS SUITABLE DISPOSAL AND BOY. EXERCISE JOY MAN CHILDREN REJOICED.</span>
                </div>

                <img src={Hero} alt="" />

                <div className={css.container}>
                  <span>100K</span>
                  <span>HAPPY CUSTOMERS WITH US</span>
                </div>
            </div>

            <div className={css.review}>Reviews</div>


            <div className={css.carousel}>

                <Swiper
                slidesPerView={3}
                slidesPerGroup={1}
                spaceBetween={20}
                className={css.tCraousel}
                breakpoints={{
                    856:{
                        slidesPerView: 3
                    },
                    640:{
                        slidesPerView: 2
                    },
                    0:{
                        slidesPerView: 1
                    }

                }}
                >
                {
                        TestimonialsData.map((t,i)=>(
                            <SwiperSlide>
                                <div key={i} className={css.testimonial}>
                                    <img src={t.image} alt="" />
                                    <span>{t.comment}</span>
                                    <hr />
                                    <span>{t.name}</span>
                                </div>
                            </SwiperSlide>
                        ))
                    }

                </Swiper>

            </div>
        </div>
     );
}
 
export default Testimonials;