import css from  './header.module.css';
import logo from '../../assets/logo.png'
import {CgShoppingBag} from 'react-icons/cg'
import {GoThreeBars} from 'react-icons/go'
import { useState } from 'react';

const Header = () => {


    const [show , setShow]= useState(true)

    const toggelMenu =()=> {
        setShow((show)=>!show)

    }




    return ( 

        <div className={css.container}>

            <div className={css.logo}>
                <img src={logo} alt="" />
                <span>Amazon</span>
            </div>



            <div className={css.right}>

                <div onClick={toggelMenu} className={css.bars}>
                    <GoThreeBars/>
                </div>


                <ul className={css.menu} style={{display: show ? 'inherit' : 'none'}}>
                        <li>Collection</li>
                        <li>Brands</li>
                        <li>new</li>
                        <li>Sales</li>
                        <li>ENG</li>
                    </ul>
    

                <input type="text" placeholder='Search' className={css.search}/>

                <CgShoppingBag className={css.card}/>
            </div>

        </div>
        
     );
}
 
export default Header;