import css from './hero.module.css'
import Heroimg from '../../assets/hero.png'
import {RiShoppingBagFill} from 'react-icons/ri'
import { BsArrowRight } from 'react-icons/bs';
import {motion} from 'framer-motion'


const Hero = () => {

    const transition = {duration: 3, type: 'spring'}



    return ( 

        <div className={css.container}>


            {/* {left side} */}
            <div className={css.hsides}>
                <span className={css.text1}>Skin Protection Creams</span>
                <div className={css.text2}>
                    <span>Trendly Collection</span>
                    <span>Seedily say has suitable disposal and boy. Exercise joy man children rejoiced.</span>
                </div>
            </div>


            {/* {middel side} */}
            <div className={css.wrapper}>

                {/* {blue circle} */}

                <motion.div
                initial={{bottom:"2rem"}} 
                whileInView={{bottom:"0rem"}}
                transition={transition}
                className={css.blueCircle}></motion.div>

                {/* {image} */}

                <motion.img
                initial={{bottom:"-2rem"}}
                whileInView={{bottom:"0rem"}}
                transition={transition}
                 src={Heroimg} width={600} alt="" />


                {/* {best sign up offer card} */}

                <motion.div
                transition={transition}
                initial={{right:"10%"}}
                whileInView={{right:'5%'}} 
                className={css.cart2}>
                    <RiShoppingBagFill/>

                    <div className={css.signup}>
                        <span>Best Signup Offers</span>
                        <div>
                        <BsArrowRight/>
                        </div>
                    </div>
                </motion.div>


            </div>

            {/* {right side} */}

            <div className={css.hsides2}>

                <div className={css.traffic}>
                    <span>1.5m</span>
                    <span>Monthly Traffic</span>
                </div>

                <div className={css.customers}>
                    <span>100k</span>
                    <span>Happy Customers</span>
                </div>

            </div>




        </div>

     );
}
 
export default Hero;