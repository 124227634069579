import {Swiper, SwiperSlide} from "swiper/react";
import { Pagination, Navigation } from "swiper";
import {SliderProducts} from '../../data/products'
import './slider.css'


//import swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'



const Slider = () => {
    return ( 
        <div className="s-container">
            <Swiper
            breakpoints={{
                856: {
                    slidesPerView: 3
                },
                640: {
                    slidesPerView: 2
                },
                0:{
                    slidesPerView: 1
                }
            }}
            modules={[Pagination,Navigation]}
            loopFillGroupWithBlank={true}
            navigation={true}
            className="mySwiper"
            slidesPerView={3}
            spaceBetween={40}
            slidesPerGroup={1}
            loop={true}
            >
                
                    {SliderProducts.map((slides,i)=>(
                        <SwiperSlide>

                            <div key={i} className="left-s">

                                <div className= "name">
                                    <span>
                                        {slides.name}
                                    </span>
                                    <span>
                                        {slides.detail}
                                    </span>
                                </div>

                                <span>{slides.price} $</span>
                                <div>Shop now</div>
                            </div>

                            <img src={slides.img} alt="product" className="imgproduct" />


                        </SwiperSlide>
                    ))}
                


            </Swiper>

        </div>
     );
}
 
export default Slider;